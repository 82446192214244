import { TweenMax } from 'gsap';

/* eslint-disable */
type Testimonials = {
  previousTestimonial: number;
  currentTestimonial: number;
  testimonialCount: number;
  lock: boolean;
  init(): void;
  targetTestimonial(testimonialIndex: number): HTMLElement;
  decrement: {
    '@click'(): void;
  };
  increment: {
    '@click'(): void;
  };
};

declare global {
  interface Window {
    testimonials(maxCount: number): Testimonials;
  }
}
/* eslint-enable */

/* eslint-disable func-names */
/* eslint-disable no-plusplus */

function testimonials(maxCount: number): Testimonials {
  return {
    previousTestimonial: null,
    currentTestimonial: 1,
    testimonialCount: maxCount,
    lock: false,
    init() {
      // Check there is more than one testimonial
      if (maxCount > 1) {
        // Hide all testimonial except first
        for (let i = 2; i <= maxCount; i++) {
          const el = this.targetTestimonial(i);

          TweenMax.set(el, {
            autoAlpha: 0,
            display: 'none',
          });
        }
      }

      this.$watch('currentTestimonial', (count: number) => {
        // If count is lower than min go to end
        if (count < 1) {
          this.currentTestimonial = maxCount;
        }

        // If count is higher than max go to start
        if (count > maxCount) {
          this.currentTestimonial = 1;
        }

        // Testimonial leave
        const leaveEl = this.targetTestimonial(this.previousTestimonial);

        // Wait for browser paint to get leave element width + height
        requestAnimationFrame(() => {
          // Fix page jump on first testimonial update
          TweenMax.set(leaveEl.parentElement, {
            width: leaveEl.offsetWidth,
            height: leaveEl.offsetHeight,
          });

          // Allow enter element to move on top of leave element
          TweenMax.set(leaveEl, {
            position: 'absolute',
            top: leaveEl.offsetTop,
            left: leaveEl.offsetLeft,
            width: leaveEl.offsetWidth,
            height: leaveEl.offsetHeight,
          });

          TweenMax.fromTo(
            leaveEl,
            1,
            {
              x: 0,
              autoAlpha: 1,
            },
            {
              ease: 'back.out(1.4)',
              x: -20,
              autoAlpha: 0,
              onStart: () => {
                // Throttle increment/decrement depending on animation
                this.lock = true;
              },
            }
          );
        });

        // Testimonial enter
        const enterEl = this.targetTestimonial(this.currentTestimonial);

        requestAnimationFrame(() => {
          /**
           * Cleanup element leave page jump fix
           *
           * enterEl.parentElement.removeProperty is not available,
           * so we will remove all style
           */
          enterEl.parentElement.removeAttribute('style');

          /**
           * Cleanup element leave absolute position
           */
          enterEl.removeAttribute('style');

          TweenMax.fromTo(
            enterEl,
            1,
            {
              x: -20,
              autoAlpha: 0,
              display: 'flex',
            },
            {
              ease: 'back.out(1.4)',
              x: 0,
              autoAlpha: 1,
              delay: 0.4, // Wait a bit for leave element to move out
              onStart: () => {
                // Open up increment/decrement depending on animation
                this.lock = false;
              },
            }
          );
        });
      });
    },
    targetTestimonial(testimonialIndex) {
      const container = this.$refs['testimonial-container'] as HTMLDivElement;
      const numChildren = container.children.length;
      if (testimonialIndex > numChildren) {
        throw new Error(`Testimonial index ${testimonialIndex} is out of bounds`);
      }
      return container.children[testimonialIndex - 1] as HTMLDivElement;
    },
    decrement: {
      '@click': function () {
        // Throttle check
        if (!this.lock) {
          this.previousTestimonial = this.currentTestimonial;

          this.currentTestimonial--;
        }
      },
    },
    increment: {
      '@click': function () {
        // Throttle check
        if (!this.lock) {
          this.previousTestimonial = this.currentTestimonial;

          this.currentTestimonial++;
        }
      },
    },
  };
}

window.testimonials = testimonials;
