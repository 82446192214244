/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */

function slideUp(target, duration = 500) {
  duration = duration ?? 500;

  target.style.transitionProperty = 'height';
  target.style.transitionDuration = `${duration}ms`;
  target.style.boxSizing = 'border-box';
  target.style.height = `${target.offsetHeight}px`;
  target.offsetHeight; // Do not remove: If removed, slide won't work
  target.style.overflow = 'hidden';
  target.style.height = 0;

  window.setTimeout(() => {
    target.style.display = 'none';
    target.style.removeProperty('height');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
  }, duration);
}

window.slideUp = slideUp;

function slideDown(target, duration = 500) {
  duration = duration ?? 500;

  target.style.removeProperty('display');

  const { display } = window.getComputedStyle(target);
  target.style.display = display === 'none' ? 'block' : display;

  const height = target.offsetHeight;

  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.offsetHeight; // Do not remove: If removed, slide won't work
  target.style.boxSizing = 'border-box';
  target.style.transitionProperty = 'height';
  target.style.transitionDuration = `${duration}ms`;
  target.style.height = `${height}px`;

  window.setTimeout(() => {
    target.style.removeProperty('height');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
  }, duration);
}

window.slideDown = slideDown;

function slideToggle(target, duration = 500) {
  duration = duration ?? 500;

  if (window.getComputedStyle(target).display === 'none') {
    return slideDown(target, duration);
  }

  return slideUp(target, duration);
}

window.slideToggle = slideToggle;
