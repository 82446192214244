function tableCellWidth() {
  return {
    setWidth(rootElement: HTMLElement): void {
      Array.from(rootElement.querySelectorAll('td[data-colwidth]')).forEach(
        (tdElement: HTMLElement) => {
          const width = tdElement.getAttribute('data-colwidth');
          tdElement.setAttribute('style', `width: ${width}px`);
        },
      );
    },
  };
}
window.tableCellWidth = tableCellWidth;
