import { siteImproveEventPush } from '../plugins/siteimprove';

type TopBar = {
  // eslint-disable-next-line no-unused-vars
  sendUniverseEvent(univers: string): void;
  // eslint-disable-next-line no-unused-vars
  isActive(url: string, segment: string): boolean;
};

// eslint-disable-next-line no-unused-vars
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    topBar(): TopBar;
  }
}

function topBar(): TopBar {
  return {
    sendUniverseEvent(univers) {
      if (window.innerWidth >= 640) {
        siteImproveEventPush(
          null,
          'Universer på desktop',
          'gå til nyt univers',
          univers
        );
      } else {
        siteImproveEventPush(
          null,
          'Universer på mobil',
          'gå til nyt univers',
          univers
        );
      }
    },
    isActive(url, segment) {
      switch (url) {
        case '/erhverv':
          return segment === 'erhverv';
        case '/nyt-sundt':
          return segment === 'nyt-sundt';
        case '/for-behandlere':
          return segment === 'for-behandlere';
        case '/':
          if (segment === 'erhverv' || segment === 'nyt-sundt' || segment === 'therapists' || segment === 'for-behandlere') return false;

          return true;
        default:
          return false;
      }
    },
  };
}

window.topBar = topBar;
