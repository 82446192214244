/* eslint-disable dot-notation */
type AudioController = {
  duration: HTMLSpanElement;
  timeLeft: HTMLSpanElement;
  audioSeeker: number;
  player: HTMLAudioElement;
  playAudio: () => void;
  calculateTime: () => void;
  isPlaying: boolean;
  onAudioSeekerChanged: () => void;
};

function parseTime(time: number) {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${minutes}:${returnedSeconds}`;
}

function isSafari() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('safari') !== -1) {
    if (ua.indexOf('chrome') > -1) {
      return false; // Chrome
    }
  }
  return true; // Safari
}

function audioController(): AudioController {
  return {
    audioSeeker: 0,
    isPlaying: false,
    get player() {
      return this.$refs['audio_player'] as HTMLAudioElement;
    },
    get duration() {
      return this.$refs['duration'] as HTMLSpanElement;
    },
    get timeLeft() {
      return this.$refs['timeLeft'] as HTMLSpanElement;
    },

    calculateTime() {
      if (isSafari()) {
        const audioSrc = this.player.children[0].attributes.getNamedItem('src').value;
        this.player.src = audioSrc;
      }
      setInterval(() => {
        if (this.player.readyState > 0) {
          const totalTime = this.player.duration;
          const currTime = this.player.currentTime;
          const seekerValue = (currTime / totalTime) * 100;

          this.audioSeeker = Math.floor(seekerValue);
          this.duration.innerText = `${parseTime(currTime)}`;
          this.timeLeft.innerText = `${parseTime(totalTime - currTime)}`;
        }
      }, 10);
    },
    playAudio() {
      if (this.isPlaying) {
        this.player.pause();
        this.isPlaying = false;
      } else {
        this.player.play();
        this.isPlaying = true;
      }
    },
    onAudioSeekerChanged() {
      const totalTime = this.player.duration;
      const currentTime = (totalTime / 100) * this.audioSeeker;
      this.player.currentTime = Math.floor(currentTime);
    },
  };
}

window.audioController = audioController;
