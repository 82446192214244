import { siteImproveEventPush } from '../plugins/siteimprove';

type DesktopNav = {
  otherSubnavIsOpen: boolean;
  navHover: boolean;
  subnavOpen?: boolean;
  sendDidClickMenuEvent(menuName: string, parent?: string): void;
}

// eslint-disable-next-line no-unused-vars
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    // eslint-disable-next-line no-unused-vars
    desktopNav(hasChildren: boolean): DesktopNav;
  }
}

function desktopNav(hasChildren: boolean): DesktopNav {
  const category: string = 'Desktop menu';

  return {
    navHover: false,
    ...(hasChildren && {
      subnavOpen: false,
      otherSubnavIsOpen: false,
    }),
    sendDidClickMenuEvent(menuName, parent) {
      parent !== undefined
        ? siteImproveEventPush(null, category, parent, menuName)
        : siteImproveEventPush(null, category, menuName);
    },
  };
}

window.desktopNav = desktopNav;
