export function stickyObserver() {
  const stickyEl = document.querySelector('.sticky-container-top');
  const nextSiblingEl = stickyEl.previousElementSibling;

  const handler = (entries) => {
    if (!entries[0].isIntersecting) {
      // set class when it is sticky
      stickyEl.classList.add('is-sticky');
    } else {
      // remove class when it is not sticky
      stickyEl.classList.remove('is-sticky');
    }
  };

  // create the observer
  const observer = new window.IntersectionObserver(handler, {
    threshold: [0, 0.25, 0.5, 0.75, 1],
  });
  // give the observer some dom nodes to keep an eye on
  observer.observe(nextSiblingEl);
}

window.stickyObserver = stickyObserver;
