/* eslint-disable max-len */
/* eslint-disable dot-notation */
type StepByStep = {
  drawLine: () => void;
  canvas: HTMLCanvasElement;
  startDiv: HTMLDivElement;
  steps: HTMLDivElement[];
  component: HTMLDivElement;
};

const STROKE_COLOR = '#B1CFF4';

function getRandomInt(min, max) {
  const roundedMin = Math.ceil(min);
  const roundedMax = Math.floor(max);
  return Math.floor(Math.random() * (roundedMax - roundedMin + 1)) + roundedMin;
}

function stepByStep(): StepByStep {
  return {
    get startDiv() {
      return this.$refs['text-box'] as HTMLDivElement;
    },
    get steps() {
      return this.$refs['steps'].children as HTMLDivElement[];
    },
    get canvas() {
      return this.$refs['canvas'] as HTMLCanvasElement;
    },
    get component() {
      return this.$refs['component'] as HTMLDivElement;
    },
    drawLine() {
      this.canvas.height = this.component.offsetHeight;
      this.canvas.width = this.component.offsetWidth;

      const isMobile = document.documentElement.clientWidth < 1024;
      const ctx = this.canvas.getContext('2d');
      ctx.strokeStyle = STROKE_COLOR;

      ctx.setLineDash([4, 6]);
      const { x: canvasX, y: canvasY } = this.canvas.getBoundingClientRect();

      const {
        right: startRight,
        y: startY,
        height: startHeight,
        top: startTop,
        bottom: startBottom,
      }: DOMRect = this.startDiv.getBoundingClientRect();

      const stepsPoint: DOMRect =
        this.steps[0].children[0].getBoundingClientRect();

      if (!isMobile) {
        ctx.moveTo(startRight - canvasX, startY - canvasY + startHeight / 2);

        ctx.bezierCurveTo(
          getRandomInt(startRight - canvasX, stepsPoint.left - canvasX),
          getRandomInt(startBottom - canvasY, startTop - canvasY),
          getRandomInt(startRight - canvasX, stepsPoint.left - canvasX),
          getRandomInt(startBottom - canvasY, startTop - canvasY),
          stepsPoint.x - canvasX + stepsPoint.width / 2,
          stepsPoint.y - canvasY + stepsPoint.height / 2
        );
      }

      if (isMobile) {
        const currPoint =
          this.steps[0].children[0].children[0].getBoundingClientRect();

        ctx.moveTo(
          currPoint.x - canvasX + currPoint.width / 2,
          currPoint.y - canvasY + currPoint.height / 2
        );
      }

      for (let i = 1; i < this.steps.length; i++) {
        const prevPoint = this.steps[i - 1].children[0].getBoundingClientRect();
        const currPoint = this.steps[i].children[0].getBoundingClientRect();

        const endX = currPoint.x - canvasX + currPoint.width / 2;
        const endY = currPoint.y - canvasY + currPoint.height / 2;

        const x1 = getRandomInt(
          currPoint.left - canvasX,
          currPoint.right - canvasX
        );

        const y1 = getRandomInt(
          prevPoint.bottom - canvasY,
          currPoint.top - canvasY
        );

        /// Stop overlap from y1 and x1

        const x2 = getRandomInt(
          currPoint.left - canvasX,
          currPoint.right - canvasX
        );

        const y2 = getRandomInt(
          prevPoint.bottom - canvasY,
          currPoint.top - canvasY
        );

        // DEBUGGING INFO
        // console.table({ x1, y1, x2, y2, endX, endY });

        // ctx.moveTo(
        //   prevPoint.x - canvasX + prevPoint.width / 2,
        //   prevPoint.y - canvasY + prevPoint.height / 2
        // );
        
        ctx.bezierCurveTo(x1, y1, x2, y2, endX, endY);

        // Debugging
        // ctx.moveTo(x1, y1);
        // ctx.arc(x1, y1, 12, 0, 2 * Math.PI);
        // ctx.fill()
        // ctx.stroke()

        // ctx.moveTo(x2, y2);
        // ctx.arc(x2, y2, 12, 0, 2 * Math.PI);
        // ctx.fill()
        // ctx.stroke()
      }

      ctx.stroke();
    },
  };
}

window.stepByStep = stepByStep;
