import { Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.data('CardsAnimation', () => ({
  animateCards() {
    const heartCardsContainer = this.$refs.heartCardContainer as HTMLDivElement;

    const heartCards = Array.from(heartCardsContainer.children);

    heartCards.forEach((card) => {
      card.classList.add('heart-card-animation');
    });
  },
}));
