import { isTouch } from '../util/isTouch';

type SubsidySymbol = {
  open: boolean
  isTouch: boolean
}

// eslint-disable-next-line no-unused-vars
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    subsidySymbol(): SubsidySymbol;
  }
}

function subsidySymbol(): SubsidySymbol {
  return {
    open: false,
    isTouch,
  };
}

window.subsidySymbol = subsidySymbol;
