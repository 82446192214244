type Accordion = {
  numOfElements: number;
  activeIndex?: number;
  focusIndex?: number;
  onToggle(index: number): void;
  onKeyChange(event: KeyboardEvent): void;
};

function SDKAccordion(numOfElements: number): Accordion {
  return {
    numOfElements,
    activeIndex: null,
    focusIndex: null,
    onToggle(index: number) {
      this.activeIndex === index
        ? (this.activeIndex = null)
        : (this.activeIndex = index);
    },
    onKeyChange(event) {
      const { keyCode } = event;
      // 38 = Up, 40 = Down
      const isKeyUpOrDown = keyCode === 38 || keyCode === 40;
      // 33 = Page Up, 34 = Page Down
      const isCtrlModifier =
        event.ctrlKey && (keyCode === 33 || keyCode === 34);
      const isDownDirection = keyCode === 34 || keyCode === 40;
      // 35 = End, 36 = Home keyboard operations
      const isHomeOrEndKey = keyCode === 35 || keyCode === 36;

      if (isKeyUpOrDown || isCtrlModifier) {
        const direction = isDownDirection ? 1 : -1;
        const newIndex =
          (this.focusIndex + this.numOfElements + direction) %
          this.numOfElements;

        this.$refs[`accordion${newIndex}`].focus();
        event.preventDefault();
      } else if (isHomeOrEndKey) {
        // eslint-disable-next-line default-case
        switch (keyCode) {
          case 36: // home
            this.$refs.accordion0.focus();
            break;
          case 35: // end
            this.$refs[`accordion${this.numOfElements - 1}`].focus();
            break;
        }
        event.preventDefault();
      }
    },
  };
}

window.SDKAccordion = SDKAccordion;
