function wrapWords(quoteElement: HTMLQuoteElement) {
  const quote = quoteElement.innerText;
  const words = quote.split(' ');

  const wordsWithSpans = words.map((word) => {
    return word.length !== 0 ? `<span>${word}</span>` : word.trim();
  });

  quoteElement.innerHTML = wordsWithSpans.join(' ');
}

window.wrapWords = wrapWords;
