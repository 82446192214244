import { siteImproveEventPush } from '../plugins/siteimprove';

import { stickyObserver } from './stickyObserver';

interface ModalTrackingEvent {
  category: string
  action: string
  label?: string
}

interface ToggleModalEvent {
  tracking: ModalTrackingEvent
  id: string
}

type SDKModal = {
  modalId: string | null; // unique identifier for the modal
  isModalOpen: boolean;
  showModal(data: ToggleModalEvent): void;
  closeModal(): void;
  onKeydownEvent(event: KeyboardEvent): void;
  stickyButton(): void;
}

// eslint-disable-next-line no-unused-vars
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    sdkModal(): SDKModal;
  }
}

function sdkModal(): SDKModal {
  const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

  let modal = null;
  let firstFocusableElement = null;
  let focusableContent = null;
  let lastFocusableElement = null;

  return {
    modalId: null,
    isModalOpen: false,
    showModal(data: ToggleModalEvent) {
      this.modalId = data.id;
      this.isModalOpen = true;
      siteImproveEventPush(null, data.tracking.category, data.tracking.action, data.tracking.label);
      modal = document.getElementById(`sdk_modal_${data.id}`);
      firstFocusableElement = modal.querySelectorAll(focusableElements)[0] as HTMLElement;
      focusableContent = modal.querySelectorAll(focusableElements);
      lastFocusableElement = focusableContent[focusableContent.length - 1] as HTMLElement;
    },
    closeModal() {
      this.modalId = null;
      this.isModalOpen = false;
      modal = null;
      firstFocusableElement = null;
      focusableContent = null;
      lastFocusableElement = null;
    },
    onKeydownEvent(event) {
      const { keyCode } = event;
      const isTabPressed = event.key === 'Tab' || keyCode === 9;

      if (!isTabPressed || !this.isModalOpen) {
        return;
      }

      if (event.shiftKey) { // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus(); // add focus for the last focusable element
          event.preventDefault();
        }
      } else {
        if (!modal.contains(document.activeElement)) {
          firstFocusableElement.focus(); // add focus for the first focusable element
          event.preventDefault();
          return;
        }
        // if focused has reached to last focusable element
        // then focus first focusable element after pressing tab
        if (document.activeElement === lastFocusableElement) {
          firstFocusableElement.focus();
          event.preventDefault();
        }
      }
    },
    stickyButton() {
      stickyObserver();
    },
  };
}

window.sdkModal = sdkModal;
