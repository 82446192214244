import axios from 'axios';

const MEMBER_MEETING_RESET_EVENT = 'clearMemberMeetingSignupEvent';

type MemberMeetingSignup = {
  formData: MemberMeetingSignupData;
  errors: Partial<Record<keyof MemberMeetingSignupData, string[]>>;
  showSignupButton: boolean;
  showForm: boolean;
  showReceipt: boolean;
  toggleSignupButton: (state?: boolean) => void;
  toggleForm: (state?: boolean) => void;
  toggleReceipt: (state?: boolean) => void;
  resetFormValues: () => void;
  submitForm: (e: Event) => void;
  init: () => void;
  formOnChange: (e: Event) => void;
};

type MemberMeetingSignupData = {
  meetingId: string;
  name: string;
  address: string;
  zipcode?: number;
  city: string;
  email?: string;
  confirmedMembership: boolean;
};

declare global {
  interface Window {
    memberMeetingSignup: typeof memberMeetingSignup;
  }
}

function memberMeetingSignup(): MemberMeetingSignup {
  return {
    formData: {
      meetingId: '',
      name: '',
      address: '',
      zipcode: null,
      city: '',
      email: null,
      confirmedMembership: false,
    },
    errors: {},
    showSignupButton: true,
    showForm: false,
    showReceipt: false,
    toggleSignupButton(state?: boolean) {
      this.showSignupButton =
        typeof state == 'undefined' ? !this.showSignupButton : state;
    },
    toggleForm(state?: boolean) {
      this.toggleSignupButton(false);
      this.showForm = typeof state == 'undefined' ? !this.showForm : state;
    },
    toggleReceipt(state?: boolean) {
      this.showReceipt =
        typeof state == 'undefined' ? !this.showReceipt : state;
    },
    resetFormValues() {
      // this.formData.meetingId = '';
      this.formData.name = '';
      this.formData.address = '';
      this.formData.zipcode = '';
      this.formData.city = '';
      this.formData.email = null;
      this.formData.confirmedMembership = false;
      this.errorMessage = [];
    },
    init() {
      window.addEventListener(MEMBER_MEETING_RESET_EVENT, (e: CustomEvent) => {
        this.resetFormValues();
        this.toggleForm(false);
        this.toggleReceipt(false);
        this.toggleSignupButton(true);
        this.$refs["memberMeetingForm"].reset();
      });
    },
    submitForm(e) {
      const meetingId = e.target[0].value;
      this.formData.email =
        this.formData.email?.length > 0 ? this.formData.email : null;
      this.errorMessage = [];
      axios
        .post('/api/member-meetings/signup', { ...this.formData, meetingId })
        .then((_) => {
          this.toggleForm(false);
          this.toggleReceipt(true);
          this.resetFormValues();
        })
        .catch((e) => {
          if (e?.response?.data) {
            // Render Errors
            try {
              const errors = e.response.data.errors;
              this.errors = errors;
            } catch (e) {
              console.error('Unable To Parse Errors', e);
            }
          }
        });
    },
    formOnChange(e: any) {
      const formElementName = e.target?.name;
      if (Object.keys(this.formData).includes(formElementName)) {
        delete this.errors[formElementName];
      }
    },
  };
}

window.memberMeetingSignup = memberMeetingSignup;
