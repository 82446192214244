function SelfserviceWidget() {
  function fetchManifest(apiUrl: string, callback: Function) {
    const manifestJson = sessionStorage.getItem('SELF_SERVICE_MANIFEST');
    let manifest = null;
    let manifestIsValid = false;

    if (manifestJson != null) {
      manifest = JSON.parse(manifestJson);
      const now = new Date();
      const expiration = new Date(manifest.expiration);
      if (now.getTime() < expiration.getTime()) {
        manifestIsValid = true;
      }
    }

    if (manifestIsValid) {
      callback(manifest);
    } else {
      fetch(`https://${apiUrl}/manifest.json`)
        .then((res) => res.json())
        .then((data) => {
          // calculate expiration time for content,
          // to force periodic refresh after 10 minutes
          const expiration = new Date();

          const manifestData = {
            ...data,
            expiration: expiration.setMinutes(expiration.getMinutes() + 1),
          };

          sessionStorage.setItem(
            'SELF_SERVICE_MANIFEST',
            JSON.stringify(manifestData)
          );
          callback(manifestData);
        });
    }
  }

  function createScriptElement(src: string) {
    const widget = document.createElement('script');

    widget.async = true;
    widget.src = src;

    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(widget, s);
  }

  function loggedIn() {
    return (
      document.cookie.indexOf('persist%3Asession=') !== -1 &&
      document.cookie.indexOf('authenticated%22:%22true') !== -1
    );
  }

  return {
    isLoaded: false,
    loggedIn: loggedIn(),
    fetchWidget(apiUrl: string, appUrl: string) {
      this.isLoaded = false;

      fetchManifest(apiUrl, (data) => {
        this.isLoaded = true;
        createScriptElement(`https://${appUrl}/widget/widget.bundle.js`);
      });
    },
    fetchApp(apiUrl: string, appUrl: string) {
      this.isLoaded = false;

      fetchManifest(apiUrl, (data) => {
        this.isLoaded = true;

        const entry = Object.values(data).find(
          (entry) => entry['isEntry'] === true
        );

        if (entry) {
          createScriptElement(`https://${appUrl}/${entry['file']}`);
        }
      });
    },
  };
}

window.SelfserviceWidget = SelfserviceWidget;
