/* eslint-disable dot-notation */

type VideoController = {
  video: HTMLVideoElement;
  onInit: () => void;
};

function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
    // @ts-ignore
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    // @ts-ignore
    document.webkitExitFullscreen();
    // @ts-ignore
  } else if (document.msExitFullscreen) {
    /* IE11 */
    // @ts-ignore
    document.msExitFullscreen();
  }
}

function videoController(): VideoController {
  return {
    get video() {
      return this.$refs['video'] as HTMLVideoElement;
    },
    onInit() {
      this.video.addEventListener('ended', () => {
        closeFullscreen();
        this.video.load();
      });
    },
  };
}

window.videoController = videoController;
