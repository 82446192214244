interface PlayListItemSource {
  src: string;
  type: string;
}

interface PlaylistItem {
  sources: Array<PlayListItemSource>;
  poster: string;
}

type VideoPlayer = {
  playlist: Array<PlaylistItem>;
  showPlayer: boolean;
  setupVideoPlayer(playlist: any[]): any;
};

// @ts-ignore
// eslint-disable-next-line no-unused-vars
declare global {
  interface Window {
    videoPlayer(selector: string): VideoPlayer;
  }
}

function videoPlayer(data: any): VideoPlayer {
  return {
    showPlayer: false,
    playlist: [],
    setupVideoPlayer(playlist: any[]): any {
      // @ts-ignore
      const player = videojs('commercialPlayer');
      player.playlist(playlist);
      // boostrap playerlistUI plugin
      player.playlistUi({
        el: document.getElementById('commercialPlaylist'),
      });

      player.playlist.autoadvance(0);
      player.on('playlistitem', () => {
        setTimeout(() => {
          if (!player) return;

          const current = document.querySelector(
            '.vjs-playlist-item.vjs-selected'
          );
          const overflowDiv = document.querySelector(
            '.video-gallery__playlist'
          );
          const scrollTopOffset =
            overflowDiv.scrollTop -
            overflowDiv.getBoundingClientRect().top +
            current.getBoundingClientRect().top;
          const scrollLeftOffset =
            overflowDiv.scrollLeft -
            overflowDiv.getBoundingClientRect().left +
            current.getBoundingClientRect().left;

          overflowDiv.scrollTo({
            top: scrollTopOffset,
            left: scrollLeftOffset,
            behavior: 'smooth',
          });
        }, 200);
      });
      return player;
    },
  };
}

window.videoPlayer = videoPlayer;
