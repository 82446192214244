type StringLike = string | number | Symbol;

// eslint-disable-next-line no-unused-vars
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    siteImproveEventPush: any;
    _sz: any;
  }
}

export function siteImproveEventPush(
  event: any,
  category: StringLike,
  action: StringLike,
  label?: StringLike
): void {
  if (typeof window._sz?.push === 'function') {
    window._sz.push(['event', category, action, label]);
  }

  return event;
}

window.siteImproveEventPush = siteImproveEventPush;
